// extracted by mini-css-extract-plugin
export var commonEvent = "events-module--commonEvent--0afad";
export var description = "events-module--description--f8833";
export var descriptionClosed = "events-module--descriptionClosed--3a832";
export var descriptionOpen = "events-module--descriptionOpen--5270a";
export var eventTitle = "events-module--eventTitle--10432";
export var icons = "events-module--icons--09267";
export var info = "events-module--info--2b8e5";
export var label = "events-module--label--2dc05";
export var main = "events-module--main--df639";
export var occupations = "events-module--occupations--80dbe";
export var simpleEvent = "events-module--simpleEvent--739c7";
export var speakerRow = "events-module--speakerRow--c36e8";
export var speakers = "events-module--speakers--a8eba";
export var timePlace = "events-module--timePlace--bdf22";
export var title = "events-module--title--4ee07";
export var toggler = "events-module--toggler--958d0";
export var visit = "events-module--visit--02063";
export var withIcon = "events-module--withIcon--9a3b0";